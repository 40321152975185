
.aboutTitle{

}

.aboutTitleSecond{
    color: #99463A;
}

.aboutScrollBase{
    position: relative;
    background: rgba(213, 209, 195, 0.79);
    padding: 30px;
    box-shadow:
            inset 0 5px 3px rgba(0, 0, 0, 0.25),
            inset 0 -5px 3px rgba(0, 0, 0, 0.25),
            inset 5px 0 3px rgba(0, 0, 0, 0.25),
            inset -5px 0 3px rgba(0, 0, 0, 0.25);
    background-color: white;
}

.scrollTitle{
    margin:0;
    font-family: 'Red Hat Display', sans-serif;
    font-style: normal;
    line-height: 150.3%;
}

.scrollTitleBackgroundPicture{
    position: relative;
    background-image: url("../../../images/scroll0.jpg");
    background-size: cover;
    background-position: center;
    border-radius: 43px 0;
    padding: 30px;
}

.scrollTitleBackgroundPicture0{
    position: relative;
    background-image: url("../../../images/scroll0.jpg");
    background-size: cover;
    background-position: center;
    border-radius: 43px 0;
    padding: 30px;
}

.scrollTitleBackgroundPicture1{
    position: relative;
    background-image: url("../../../images/scroll1.jpg");
    background-size: cover;
    background-position: center;
    border-radius: 43px 0;
    padding: 30px;
}

.scrollTitleBackgroundPicture2{
    position: relative;
    background-image: url("../../../images/scroll2.jpg");
    background-size: cover;
    background-position: center;
    border-radius: 43px 0;
    padding: 30px;
}

.scrollTitleBackgroundPicture3{
    position: relative;
    background-image: url("../../../images/scroll0.jpg");
    background-size: cover;
    background-position: center;
    border-radius: 43px 0;
    padding: 30px;
}


.scrollTitleBack{
    display: flex;
}

.aboutScroll{
}

.aboutScrollLeft{
}

.aboutScrollRight{
}

.aboutScrollContent{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 26px;
    white-space: pre-line;
}

.section{
    display: flex;
    flex-direction: column;
}

.titleWrap{
    margin:18.720px 0;
    border-bottom: 2px solid #99463A;
}

.picturePresident{
    width: 340px;
    height: 260px;
}
.pictureWrap{
    display: flex;
    justify-content: center;
    align-items: center;
}