.serviceTitle{
    font-family: 'Red Hat Display', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 151.3%;
    letter-spacing: 0.33em;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 110px;
    margin-bottom: 40px;
}

.serviceTitleFirstCharacter{
    color: #99463A;
}

.eachServiceOutline{
}

.serviceTitleOutline{
    display: flex;
    justify-content: space-around;
    box-shadow:
            inset 0 5px 3px rgba(0, 0, 0, 0.25),
            inset 0 -5px 3px rgba(0, 0, 0, 0.25),
            inset 5px 0 3px rgba(0, 0, 0, 0.25),
            inset -5px 0 3px rgba(0, 0, 0, 0.25);
    padding:30px;
}

.serviceEachTitle{
    font-family: 'Red Hat Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #342D32;
    margin: 18.72px 0;
    border-bottom: 2px solid #99463A;
}

.serviceBorder{
    box-sizing: border-box;
}

.eachPicture{
    width: 170px;
    height: 170px;
    border-radius: 50%;
    padding: 4px;
}

.centerImage {
    display: flex;
    justify-content: center;
}

.serviceList{
    margin-top: 10px;
}

.sectionBackground{
    background-color: white;
}