.description{
    color: white;
}

.underTitleSpace{
    background: #D5D1C366;
    top: 15%;
    left: 66%;
    position: absolute;
    border-radius: 10px;
}

.titleSecundColor{
    color: #99463A;
    white-space: normal;
}

.title{
    font-family: 'Red Hat Display', sans-serif;
    font-style: normal;
    width: auto;
    font-weight: 500;
    font-size: 40px;
    line-height: 124.8%;
    color: #42444A;
}

.titleSecund{
    display: flex;
    justify-content: left;
    white-space: pre-wrap;
    font-family: 'Red Hat Display', sans-serif;
    font-style: normal;
}

.titleDescription{
    font-family: 'Red Hat Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #42444A;
    margin-left: 90px;
}