.serviceTitle{
    font-family: 'Red Hat Display', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 151.3%;
    letter-spacing: 0.33em;
    display: flex;
    left:52%;
    position: relative;
    margin: 18.72px 0;
}

.serviceTitleFirstCharacter{
    color: #99463A;
}

.eachServiceOutline{
    background-color: white;
}

.serviceTitleOutline{
    justify-content: space-around;
    margin:0;
    height: 100vh;
    padding:30px;
    box-shadow:
            inset 0 5px 3px rgba(0, 0, 0, 0.25),
            inset 0 -5px 3px rgba(0, 0, 0, 0.25),
            inset 5px 0 3px rgba(0, 0, 0, 0.25),
            inset -5px 0 3px rgba(0, 0, 0, 0.25);
}

.serviceEachTitle{
    font-family: 'Red Hat Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #342D32;
}

.serviceBorder{
    box-sizing: border-box;
    border-bottom: 2px solid #99463A;
}

.eachPicture{
    width: 170px;
    height: 170px;
    border-radius: 50%;
    padding: 4px;
}

.centerImage {
    display: flex;
    justify-content: center;
}

.serviceList{
    margin-top:15px;
}

.sectionBackground{
    background: white;
    height: 100%;
}

.titleOutline{
    margin: 18.72px 0;
    width: 50%;
}

.servicePicture{
    position: absolute;
    left: 60%;
    top: 20%;
    width: 360px;
    height: 360px;
}

.serviceTitle2{
    width: 50%;
}