.homeContentBackground {
    border-radius: 12.199px;
    margin-top: 5%;
    margin-left: 10%;
    width: 80%;
    color: #42444A;
}

.homeContent{
    left: 30%;
    position: relative;
}

.homeContentTitle{
    font-family: 'Red Hat Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 124.8%;
    padding: 4%;
}

.homeContentTitleSecond{
    color: #99463A;
}

.homeContentDescription{
    font-family: 'Red Hat Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 180.3%;
    padding: 4%;
}