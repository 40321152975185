.feeTitle{
    font-family: 'Red Hat Display', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 151.3%;
    letter-spacing: 0.33em;
    display: flex;
    left:52%;
    position: relative;
    margin-top: 110px;
    margin-bottom: 40px;
}

.feeTitleColor{
    color: #99463A;
}


.feeBackground{
    position: relative;
    margin-top: 0;
    height: 100vh;
    padding: 30px;
    box-shadow:
            inset 0 5px 3px rgba(0, 0, 0, 0.25),
            inset 0 -5px 3px rgba(0, 0, 0, 0.25),
            inset 5px 0 3px rgba(0, 0, 0, 0.25),
            inset -5px 0 3px rgba(0, 0, 0, 0.25);
}

.title{
    border-bottom: 2px solid #99463A;
    width: 50%;
}

.itemTitle{
    padding-left: 0;
}

.feeSection{
    display: block;
    background-color: white;
}

.pricePicture{
    position: absolute;
    left: 60%;
    top: 20%;
    width: 360px;
    height: 360px;
}