
.aboutTitle{
    font-family: 'Red Hat Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    position: relative;
    left: 45%;
    margin-top:110px;
    margin-bottom: 20px;
}

.aboutTitleSecond{
    color: #99463A;
}

.aboutScrollBase{
    position: relative;
    margin-top: 0;
    background: #D5D1C3C9;
    background: rgba(213, 209, 195, 0.79);
    padding: 30px;
    box-shadow:
            inset 0 5px 3px rgba(0, 0, 0, 0.25),
            inset 0 -5px 3px rgba(0, 0, 0, 0.25),
            inset 5px 0 3px rgba(0, 0, 0, 0.25),
            inset -5px 0 3px rgba(0, 0, 0, 0.25);
    margin-bottom: 0;
    background-color: white;
}

.scrollTitle{
    font-family: 'Red Hat Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    margin: 0;
}

.scrollTitleBackgroundPicture{
    position: relative;
    background-image: url("../../images/scroll0.jpg");
    background-size: cover;
    background-position: center;
    border-radius: 43px 0;
    padding: 30px;
}

.scrollTitleBackgroundPicture0{
    position: relative;
    background-image: url("../../images/scroll0.jpg");
    background-size: cover;
    background-position: center;
    border-radius: 43px 0;
    padding: 30px;
}

.scrollTitleBackgroundPicture1{
    position: relative;
    background-image: url("../../images/scroll1.jpg");
    background-size: cover;
    background-position: center;
    border-radius: 43px 0;
    padding: 30px;
}

.scrollTitleBackgroundPicture2{
    position: relative;
    background-image: url("../../images/scroll2.jpg");
    background-size: cover;
    background-position: center;
    border-radius: 43px 0;
    padding: 30px;
}

.scrollTitleBackgroundPicture3{
    position: relative;
    background-image: url("../../images/scroll0.jpg");
    background-size: cover;
    background-position: center;
    border-radius: 43px 0;
    padding: 30px;
}


.scrollTitleBack{
    border-radius: 7px;
    width: 280px;
    display: flex;
}

.aboutScroll{


}

.aboutScrollLeft{
    margin-right:0;
}

.aboutScrollRight{
    margin-left:0;
}

.aboutScrollContent{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    white-space: pre-line;
}

.titleWrap {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #99463A;
    margin:18.720px 0;
    width: 50%;
}

.contentWrap {
    position: relative;
    display: flex;
    align-items: center;
}

.picturePresident{
    width: 340px;
    height: 260px;
}

.content{
    display: flex;
    flex-direction: row;
}

.pictureContent{
    margin:auto;
}