.backScreen{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
}

.slideIn {
    transform: translateX(0);
}

.slideOut {
    transform: translateX(-100%);
}

.sideBar{
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.88);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 4px 8px rgba(0, 0, 0, 0.25), inset 2px 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 100;
    transition: transform 0.3s ease-in-out;
}

.sectionParent{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    height: 100%;
    padding-top: 20%;
}

.eachSection{
    padding: 15%;
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24.98px;
    line-height: 36px;
}

.link {
    text-decoration: none;
    color: black;
}