
.blogTitle{
    font-family: 'Red Hat Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 124.8%;
    display: flex;
    justify-content: center;
    white-space: pre-wrap;
    padding-top: 70px;
    padding-bottom: 70px;
}

.blogTitleFirst{
    color: #99463A;
}

.blogContentBase{
    position: relative;
    height: 100vh;
    background-color: white;
    padding: 30px;
    box-shadow:
            inset 0 5px 3px rgba(0, 0, 0, 0.25),
            inset 0 -5px 3px rgba(0, 0, 0, 0.25),
            inset 5px 0 3px rgba(0, 0, 0, 0.25),
            inset -5px 0 3px rgba(0, 0, 0, 0.25);
}

.blogBase{
    display: flex;
    justify-content: center;
}

.blogContentTitle{
    display: flex;
    justify-content: left;
    font-family: 'Red Hat Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #342D32;
    white-space: pre-wrap;
}

.blogContent{
    margin-bottom: 20px;
    width: 100%;
}

.blogContentLeft{
    margin-right: 25%;
}

.blogContentRight{
    margin-left: 25%;
}