.header {
    width: 100%;
    height: 66px;
    left: 0;
    top: 0;
    background: rgba(213, 209, 195, 0.6);
    box-shadow: inset 4px 7px 8px rgba(0, 0, 0, 0.2);
}

.nav {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 10%;
}

.ul {
    white-space: nowrap;
    list-style: none;
    padding: 0;
    margin: 0;
}

.li {
    display: inline-block;
    font-family: 'Jost',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-left:3%;
    margin-right:3%;
}

.logoAndNav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.headerTitle {
    margin-left:10%;
}

.headerMainTitle{
    font-family: 'Red Hat Display',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16.556px;
    line-height: 20px;
    letter-spacing: -0.025em;

    color: #42444A;
}

.headerSubTitle{
    font-family: 'Red Hat Display',sans-serif;;
    font-style: normal;
    font-weight: 700;
    font-size: 16.556px;
    line-height: 20px;
    letter-spacing: -0.025em;

    color: #42444A;
}



.logo{
}

.link {
    text-decoration: none;
    color: #42444A;
}